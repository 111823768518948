import React from 'react';
import { Outlet } from 'react-router-dom';
import useEmployee, { EmployeeContext } from '../../../hooks/useEmployee';

export default function EmployeeProvider() {
    const empContext = useEmployee();

    return (
        <EmployeeContext.Provider value={empContext}>
            <Outlet />
        </EmployeeContext.Provider>
    );
}
