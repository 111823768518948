/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, Grid, Stack, TextField, Tooltip, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import BaseCheckbox from '../../../../../components/BaseCheckbox';
import LabelWithRequired from '../../../../../components/LabelWithRequired';
import color from '../../../../../config/Colors';
import { LanguageContext } from '../../../../../contexts/useLanguage';
import { shopController } from '../../../../../controllers';
import { AuthContext } from '../../../../../hooks/useAuth';
import { CompaniesContext } from '../../../../../hooks/useCompanies';
import useMedia from '../../../../../hooks/useMedia';
import { TenantContext } from '../../../../../hooks/useTenants';
import Zone from '../../../../../models/Zone';
import { Role } from '../../../../../models/permission/Role';
import { AllocatedPermitType, AllocatedType } from '../../../../../models/tenant/AllocatedTypeWithAmount';
import { TenantWithAllocate } from '../../../../../models/tenant/Tenant';
import AllocatedTypeItem from './AllocatedTypeItem';
import { BAY_INFO_LIMIT_CHARACTER } from '../../../../permitsLayout/addEditPermit/components/UpsertPermitItem/UpsertPermitItemForm';
import { LocationsContext } from '../../../../../hooks/useLocations';
import IOSSwitch from '../../../../../components/IOSSwitch';

type Props = {
    handleClose: () => void;
    tenant: FormData;
    tenantWithAllocate: TenantWithAllocate[];
    isEdit: boolean;
};
export default function ModalAddTenant({ isEdit, ...props }: Props) {
    const { getLocationDetail } = useContext(LocationsContext);
    const { zoneDataState, locationSelect } = useContext(LocationsContext);
    const { zones, zonesSelect } = zoneDataState;
    const { handleResetDataTenant } = useContext(TenantContext);
    const { account } = useContext(AuthContext);
    const [isLoading, setLoading] = useState<boolean>(false);
    const { isExtraSmallXs, isMediumMD } = useMedia();

    const [zoneCheckedSelect, setZoneCheckedSelect] = useState<Zone[]>(zones);
    const [allChecked, setAllChecked] = useState(true);

    useEffect(() => {
        if (isEdit) {
            let zonesEdit: Zone[] = [];
            if (props.tenant.zoneIds?.length) {
                zones.forEach((item) => {
                    if (props.tenant.zoneIds?.includes(item.id)) {
                        zonesEdit.push(item);
                    }
                });
                setAllChecked(false);
            } else {
                zonesEdit = zones;
                setAllChecked(true);
            }
            setZoneCheckedSelect(zonesEdit);
        }
    }, []);

    const { t } = useContext(LanguageContext);

    const schema = yup.object({
        tenantName: yup.string().required(t('validation:tenantNameIsRequired')),
        managerName: yup.string().required(t('validation:managerNameIsRequired')),
        email: yup
            .string()
            .email(`${t('validation:invalidEmailFormat')}`)
            .required(`${t('validation:emailIsRequired')}`),
        bayInfo: yup.string().max(40, t('validation:bayInfoLessThan40Chars')),
        allocatedTypes: yup.array().of(
            yup.object().shape({
                isActive: yup.boolean().transform((value) => !!value),
                allocatedPermitType: yup.number(),
                usedPermit: yup.number(),
                isValid: yup.boolean().required(),
                // amount: yup
                //     .number()
                //     .when('isActive', (isActive: boolean, schema) => {
                //         const usedPermit = yup.ref('usedPermit');
                //         const validateVal = isEdit ? usedPermit : 1;
                //         if (isActive)
                //             return schema
                //                 .required('Allocated amount is required')
                //                 .min(validateVal, `Allocated amount must be greater than used permit used`);
                //         else {
                //             return schema;
                //         }
                //     })
                //     .typeError(t('validation:thisFieldIsRequired'))
                //     .required(t('validation:thisFieldIsRequired')),
            })
        ),
    });

    const [isFocused, setIsFocused] = useState(false);

    const formData = useForm<FormData>({
        mode: 'all',
        defaultValues: {
            ...props.tenant,
            allocatedTypes: isEdit ? props.tenant.allocatedTypes : allocatedTypesDefault,
        },
        resolver: yupResolver(schema),
    });

    const {
        control,
        formState: { errors, isValid, isDirty },
        setError,
        watch,
    } = formData;

    const useFields = useFieldArray({
        name: 'allocatedTypes',
        control,
    });

    const { fields } = useFields;

    const handleSubmitForm = (isSendMail: boolean) => {
        const {
            clientId,
            email,
            managerName,
            phoneNumber,
            tenantName,
            bayInfo,
            isEditBayInfo,
            maxPermits,
            allocatedTypes,
            isEnableIhubSupportFeature,
            // isLockScreenPIN,
            // isEnableITicketLite,
            // isRevealPermit,
            // pin,
        } = watch();

        const allocatedPermits =
            zoneCheckedSelect.length === zonesSelect!.length
                ? []
                : zoneCheckedSelect.map((item) => {
                      return {
                          shopClientId: clientId,
                          shopLocationId: locationSelect?.id,
                          zoneId: item.id,
                          allocatedAmount: 0,
                      };
                  });

        const tenant: TenantWithAllocate = {
            ...props.tenant,
            email,
            managerName,
            tenantName,
            clientId,
            phoneNumber,
            locationId: locationSelect?.id,
            isSendMail,
            maxPermits: Number(maxPermits),
            maxAllocatedAmount: Number(maxPermits),
            // isVerify: isSendMail,
            allocatedPermits,
            isEditBayInfo,
            bayInfo,
            isEnableIhubSupportFeature,
            // isLockScreenPIN,
            // isEnableITicketLite,
            // pin: isLockScreenPIN ? pin : null,
            // isRevealPermit,
            allocatedTypes: allocatedTypes?.map((at) => {
                return { ...at, locationId: locationSelect!.id, amount: at.isActive ? at.amount : 0 };
            }),
        };

        setLoading(true);
        shopController
            .upsert(tenant)
            .then((res) => {
                //close popup
                props.handleClose();
                //refresh tenant
                handleResetDataTenant();
                //refresh location detail
                getLocationDetail();
                //popup success
                toast.success(t('notification:changesSaved'));
            })
            .catch((err) => {
                if (err?.response?.status === 409) {
                    setError('email', { message: t('validation:emailIsAlreadyRegistered') });
                }
                if (err?.response?.status !== 500) toast.error(err?.response?.data?.value);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCheckedZone = (zone: Zone) => {
        let zoneCheckedSelectClone = [...zoneCheckedSelect];
        const item = zoneCheckedSelectClone.find((item) => item.id === zone.id);

        if (item) {
            zoneCheckedSelectClone = zoneCheckedSelectClone.filter((item) => item.id !== zone.id);
        } else {
            zoneCheckedSelectClone.push(zone);
        }
        setZoneCheckedSelect(zoneCheckedSelectClone);
        setAllChecked(zoneCheckedSelectClone.length === zones.length);
    };

    const handleChangeCheckedAll = (checked: boolean) => {
        setZoneCheckedSelect(checked ? zones : []);
    };

    const isZoneScopeValid = zoneCheckedSelect.length > 0;
    const isAllPermitTypeValid = watch().allocatedTypes?.every((item) => item.isValid === true);
    return (
        <Box sx={{ padding: '40px 24px' }}>
            <Grid container gap={{ xs: 1, md: 3 }}>
                <Typography variant="h4" sx={{ textAlign: 'center', width: '100%' }}>
                    {isEdit ? t('companies:tenants.editTenant') : t('companies:tenants.addTenant')}
                </Typography>
                <Grid
                    item
                    container
                    xs={12}
                    mt={2}
                    sx={{
                        '& .MuiFormHelperText-root': {
                            fontSize: isExtraSmallXs || isMediumMD ? '10px' : '12px',
                        },
                    }}
                >
                    <Grid item container spacing={2}>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name={'tenantName'}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        error={!!errors.tenantName?.message}
                                        helperText={errors.tenantName?.message as any}
                                        fullWidth
                                        label={<LabelWithRequired label={t('companies:tenants.tenantName')} />}
                                        placeholder={t('input:enterFullName')}
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name={'managerName'}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        error={!!errors.managerName?.message}
                                        helperText={errors.managerName?.message as any}
                                        fullWidth
                                        label={<LabelWithRequired label={t('companies:tenants.managerName')} />}
                                        placeholder={t('input:enterManagerName')}
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                        inputProps={{
                                            maxLength: 50,
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} my={2}>
                            <Controller
                                control={control}
                                name={'email'}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        error={!!errors.email?.message}
                                        helperText={errors.email?.message as any}
                                        fullWidth
                                        disabled={isEdit && ![Role.AccountManager].includes(account.label)}
                                        label={<LabelWithRequired label={t('companies:tenants.emailOfTenant')} />}
                                        inputProps={{
                                            maxLength: 320,
                                        }}
                                        placeholder={t('input:enterEmailOfTenant')}
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6} my={2}>
                            <Controller
                                control={control}
                                name={'phoneNumber'}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        error={!!errors.phoneNumber?.message}
                                        helperText={errors.phoneNumber?.message as any}
                                        fullWidth
                                        label={t('companies:text.phoneNumber')}
                                        placeholder={t('input:enterContactNumber')}
                                        onChange={(e) => {
                                            field.onChange(e);
                                        }}
                                        inputProps={{
                                            maxLength: 11,
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography>{t('permits:text.permit')}:</Typography>
                            <Stack sx={{ borderRadius: '5px', background: color.grey100 }} p={2} gap={3}>
                                {fields.map((field, index) => {
                                    return (
                                        <AllocatedTypeItem
                                            fieldData={field}
                                            key={field.id}
                                            formIndex={index}
                                            useFields={useFields}
                                            formData={formData}
                                            isEdit={isEdit}
                                        />
                                    );
                                })}
                            </Stack>
                        </Grid>

                        <Grid item xs={12} wrap="nowrap">
                            <Typography>{t('companies:title.zoneAndBayInformation')}</Typography>
                            <Stack sx={{ borderRadius: '5px', background: color.grey100 }} p={2} gap={2}>
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Typography>{t('companies:text.zones')}</Typography>
                                    <BaseCheckbox
                                        label={<Typography sx={{ ml: 1 }}>{t('permits:input.AllZones')}</Typography>}
                                        checkBoxProps={{
                                            checked: allChecked,
                                            onChange(event, checked) {
                                                setAllChecked(checked);
                                                handleChangeCheckedAll(checked);
                                                // popUpWarning.setTrue();
                                            },
                                            disabled: false,
                                        }}
                                    />
                                </Stack>
                                <Box mt={0.5}>
                                    <Stack sx={{ background: color.white, padding: '12px 16px' }}>
                                        <Grid
                                            container
                                            display={'flex'}
                                            flexDirection={{ xs: 'column', sm: 'row' }}
                                            flexWrap="wrap"
                                            spacing={2}
                                        >
                                            {zones.map((item) => {
                                                return (
                                                    <Grid item xs={4} wrap="nowrap" zeroMinWidth>
                                                        <BaseCheckbox
                                                            key={item.id}
                                                            label={
                                                                <Tooltip
                                                                    arrow
                                                                    placement="top"
                                                                    title={`${item.publicName}`}
                                                                    PopperProps={{ style: { zIndex: 99999 } }}
                                                                >
                                                                    <Typography
                                                                        sx={{
                                                                            ml: 1,
                                                                            overflow: 'hidden',
                                                                            textOverflow: 'ellipsis',
                                                                            whiteSpace: 'nowrap',
                                                                            maxWidth: { xs: '100%', sm: '150px' },
                                                                        }}
                                                                    >
                                                                        {item.publicName}
                                                                    </Typography>
                                                                </Tooltip>
                                                            }
                                                            checkBoxProps={{
                                                                checked: zoneCheckedSelect.includes(item),
                                                                onChange(event, checked) {
                                                                    handleCheckedZone(item);
                                                                    // popUpWarning.setTrue();
                                                                },
                                                            }}
                                                        />
                                                    </Grid>
                                                );
                                            })}
                                        </Grid>
                                    </Stack>
                                </Box>
                                <Divider variant="middle" />
                                <Stack direction={'row'} justifyContent={'space-between'}>
                                    <Stack justifyContent={'center'}>
                                        <Typography variant="body1">{t('permits:text.bayNo')}:</Typography>
                                    </Stack>
                                    <Stack direction="column" alignItems={'flex-end'}>
                                        <Controller
                                            name="isEditBayInfo"
                                            control={control}
                                            render={({ field }) => {
                                                return (
                                                    <BaseCheckbox
                                                        label={
                                                            <Typography sx={{ ml: 1 }}>
                                                                {t('companies:text.allowTenantToEdit')}
                                                            </Typography>
                                                        }
                                                        checkBoxProps={{
                                                            checked: field.value,
                                                            onChange(event, checked) {
                                                                // popUpWarning.setTrue();
                                                                field.onChange(checked);
                                                            },
                                                            // disabled: false,
                                                        }}
                                                    />
                                                );
                                            }}
                                        />

                                        <Typography sx={{ fontSize: '10px', color: color.grey600 }}>
                                            {watch().isEditBayInfo
                                                ? t('companies:text.allowTenantToEdit')
                                                : t('companies:text.doNotAllowTenantToEdit')}
                                        </Typography>
                                    </Stack>
                                </Stack>

                                <Stack>
                                    <Box
                                        sx={{
                                            p: '12px 16px',
                                            background: color.white,
                                            '& .MuiFormHelperText-root': {
                                                position: 'absolute',
                                                top: '35px',
                                                right: '-10px !important',
                                                left: 'auto',
                                            },
                                        }}
                                    >
                                        <Controller
                                            control={control}
                                            name={'bayInfo'}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    onFocus={() => setIsFocused(true)}
                                                    onBlur={() => setIsFocused(false)}
                                                    fullWidth
                                                    // placeholder={t('permits:text.enterBayNumber')}
                                                    helperText={
                                                        isFocused &&
                                                        `${field.value?.length ?? 0}/${BAY_INFO_LIMIT_CHARACTER}`
                                                    }
                                                    onChange={(e) => field.onChange(e)}
                                                    inputProps={{
                                                        maxLength: 40,
                                                    }}
                                                />
                                            )}
                                        />
                                    </Box>
                                </Stack>
                            </Stack>
                        </Grid>

                        {/* <Grid item xs={12} wrap="nowrap">
                            <Stack mb={0.5} direction={'row'} justifyContent={'space-between'} alignItems={'start'}>
                                <Box mr={4}>
                                    <Typography>Lock Screen PIN</Typography>
                                    <Typography color={color.grey600}>
                                        You have the option to configure a PIN specific to the tenant to activate the
                                        screen lock on iPark devices.
                                    </Typography>
                                </Box>
                                <Controller
                                    name="isLockScreenPIN"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <IOSSwitch
                                            {...field}
                                            label={
                                                <Typography sx={{ minWidth: '55px' }}>
                                                    {!!field.value ? 'Enable' : 'Disable'}
                                                </Typography>
                                            }
                                            iosSwitchProps={{
                                                checked: field.value,
                                                onChange(event, checked) {
                                                    field.onChange(event);
                                                    // setValue('pin', 0);
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Stack>

                            <Stack
                                sx={{
                                    borderRadius: '5px',
                                    background: color.grey100,
                                    display: watch().isLockScreenPIN ? 'block' : 'none',
                                }}
                                p={2}
                                gap={2}
                            >
                                <Controller
                                    control={control}
                                    name={'pin'}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            error={!!errors.pin?.message}
                                            helperText={errors.pin?.message as any}
                                            placeholder={'Enter your PIN'}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^\d*$/g.test(value)) {
                                                    field.onChange(value);
                                                }
                                            }}
                                            inputProps={{
                                                maxLength: 40,
                                            }}
                                        />
                                    )}
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} wrap="nowrap">
                            <Stack mb={0.5} direction={'row'} justifyContent={'space-between'} alignItems={'start'}>
                                <Box mr={4}>
                                    <Typography>iTicket lite service</Typography>
                                </Box>
                                <Controller
                                    name="isEnableITicketLite"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <IOSSwitch
                                            {...field}
                                            label={
                                                <Typography sx={{ minWidth: '55px' }}>
                                                    {!!field.value ? 'Enable' : 'Disable'}
                                                </Typography>
                                            }
                                            iosSwitchProps={{
                                                checked: field.value,
                                                onChange(event, checked) {
                                                    field.onChange(event);
                                                    // setValue('pin', 0);
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Stack>
                        </Grid>

                        <Grid item xs={12} wrap="nowrap">
                            <Stack mb={0.5} direction={'row'} justifyContent={'space-between'} alignItems={'start'}>
                                <Box mr={4}>
                                    <Typography>Reveal permit expiry date</Typography>
                                </Box>
                                <Controller
                                    name="isRevealPermit"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <IOSSwitch
                                            {...field}
                                            label={
                                                <Typography sx={{ minWidth: '55px' }}>
                                                    {!!field.value ? 'Enable' : 'Disable'}
                                                </Typography>
                                            }
                                            iosSwitchProps={{
                                                checked: field.value,
                                                onChange(event, checked) {
                                                    field.onChange(event);
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Stack>
                        </Grid> */}

                        <Grid item xs={12} wrap="nowrap">
                            <Stack mb={0.5} direction={'row'} justifyContent={'space-between'} alignItems={'start'}>
                                <Box mr={4}>
                                    <Typography>{t('companies:tenants.enableIHubSupportFeature')}</Typography>
                                </Box>
                                <Controller
                                    name="isEnableIhubSupportFeature"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <IOSSwitch
                                            {...field}
                                            label={
                                                <Typography sx={{ minWidth: '55px' }}>
                                                    {!!field.value ? 'Enable' : 'Disable'}
                                                </Typography>
                                            }
                                            iosSwitchProps={{
                                                checked: field.value,
                                                onChange(event, checked) {
                                                    field.onChange(event);
                                                    // setValue('pin', 0);
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} display="flex" flexDirection={'row'} mt={1} justifyContent="space-between">
                    <Button variant="cancel" sx={{ minWidth: '150px' }} onClick={props.handleClose}>
                        {t('action:cancel')}
                    </Button>

                    <Stack direction={'row'} spacing={2}>
                        <LoadingButton
                            loading={isLoading}
                            startIcon={<></>}
                            loadingPosition="start"
                            variant="outlined"
                            disabled={!isValid || !isZoneScopeValid || !isAllPermitTypeValid}
                            sx={{ minWidth: '150px' }}
                            type="submit"
                            onClick={() => handleSubmitForm(false)}
                        >
                            {t('action:saveOnly')}
                        </LoadingButton>

                        <LoadingButton
                            loading={isLoading}
                            startIcon={<></>}
                            loadingPosition="start"
                            variant="contained"
                            disabled={!isValid || !isZoneScopeValid || !isAllPermitTypeValid}
                            sx={{ minWidth: '150px' }}
                            type="submit"
                            onClick={() => handleSubmitForm(true)}
                        >
                            {isEdit ? t('action:saveWithResend') : t('action:saveAndSend')}
                        </LoadingButton>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
}

const allocatedTypesDefault: AllocatedType[] = [
    {
        isValid: true,
        amount: 1,
        allocatedPermitType: AllocatedPermitType.Once,
        clientId: 0,
        isActive: true,
        locationId: 0,
    },
    {
        isValid: false,
        amount: 0,
        allocatedPermitType: AllocatedPermitType.Dynamic,
        clientId: 0,
        isActive: true,
        locationId: 0,
    },
    {
        isValid: false,
        amount: 0,
        allocatedPermitType: AllocatedPermitType.Indefinite,
        clientId: 0,
        isActive: true,
        locationId: 0,
    },
    {
        isValid: false,
        amount: 0,
        allocatedPermitType: AllocatedPermitType.Temporary,
        clientId: 0,
        isActive: true,
        locationId: 0,
    },
];

export interface ZoneValid {
    id: number;
    isValid: boolean;
}

export interface ItemChecked {
    id: number;
    checked: boolean;
}

export interface FormData {
    tenantName: string;
    managerName: string;
    email: string;
    phoneNumber: string;
    clientId?: number;
    maxPermits?: number;
    minVal?: number;
    bayInfo?: string;
    isEditBayInfo?: boolean;
    zoneIds?: number[];
    allocatedTypes?: AllocatedType[];
    isEnableIhubSupportFeature: boolean;
    // isLockScreenPIN: boolean;
    // isEnableITicketLite: boolean;
    // isRevealPermit: boolean;
    // pin: string;
}

export interface ZoneTenantItem {
    isChecked: boolean;
    totalPermits: number;
    remaining: number;
    currentRemaining: number;
    inputPermit: number | undefined | string;
    zone?: Zone;
    usedPermit: number;
    allocatedAmount: number;
    isEdit: boolean;
    greaterThan: number;
}
